import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact"; 
import { Container, Row, Col } from "reactstrap";
import instance from "base_url";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SupportTypes() {
  const { t } = useTranslation();
  const [supportTypes, setSupportTypes] = useState(null);

  // Get Support Types
  const getSupportTypes = async () => {
    try {
      const { data } = await instance.get("/support-type/");
      setSupportTypes(data.results);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getSupportTypes();
  }, []);

  //MDBDataTable
  const data = {
    columns: [
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Support Name")}
          </span>
        ),
        field: "name",
        sort: "asc",
        width: 200
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Support Kind")}
          </span>
        ),
        field: "kind",
        sort: "asc",
        width: 200
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Description")}
          </span>
        ),
        field: "description",
        sort: "asc",
        width: 200
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Action")}
          </span>
        ),
        field: "action",
        sort: "disabled",
        width: 100
      }
    ],
    rows: supportTypes ? supportTypes.map(item => ({
      name: item.name,
      kind: item.kind,
      description: item.description,
      action: (
        <Link
          to={`/support-type/${item.id}`}
          state={item.id}
          className="btn btn-primary"
        >
          {t("Details")}
        </Link>
      )
    })) : []
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">{t("Support Types")}</h6>
              </Col>
              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Link
                    to="/create-support-type"
                    className="btn btn-primary dropdown-toggle waves-effect waves-light"
                  >
                    {t("Create Support Type")}
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* Ensure MDBDataTable is only included once */}
                <MDBDataTable
                    searchLabel={t("Search")}
                    infoLabel={[t("Showing"), t("to"), t("of"), t("entries")]}
                    noRecordsFoundLabel={t("No records found")}
                    paginationLabel={[t("Previous"), t("Next"), t("End")]}
                    noBottomColumns={true}
                    responsive
                    striped
                    bordered
                    data={data}
                    displayEntries={false}
                    pagination
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
