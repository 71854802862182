import React, { useState, useEffect } from "react";
import instance from "base_url";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";

export default function Volunteers() {
  const { t } = useTranslation();
  const [volunteers, setVolunteers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);

  // Get Volunteers
  const getVolunteers = async () => {
    setIsLoading(true);
    try {
      const { data } = await instance.get("/volunteers/");
      setVolunteers(data.results);
      setIsLoading(false);
      setErrorOccurred(false);
    } catch (e) {
      setErrorOccurred(true);
      console.error("ERROR: ", e);
    }
  };

  useEffect(() => {
    getVolunteers();
  }, []);

  // Prepare data for MDBDataTable
  const data = {
    columns: [
      {
        label: t("Name"),
        field: "name",
        sort: "asc",
        width: 200
      },
      {
        label: t("Manager"),
        field: "manager",
        sort: "asc",
        width: 200
      },
      {
        label: t("Position"),
        field: "position",
        sort: "asc",
        width: 200
      },
      {
        label: t("Action"),
        field: "action",
        sort: "disabled",
        width: 100
      }
    ],
    rows: volunteers.map(volunteer => ({
      name: volunteer.individual?.name || "-",
      manager: volunteer.manager?.name || "-",
      position: volunteer.position || "-",
      action: (
        <Link to={`/volunteer/${volunteer.id}`} className="btn btn-sm btn-primary">
          {t("Details")}
        </Link>
      )
    }))
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {errorOccurred ? (
            <div>
              <p>{t("An Error Occurred!")}</p>
              <Button
                onClick={() => {
                  getVolunteers();
                }}
              >
                {t("Try again")}
              </Button>
            </div>
          ) : isLoading ? (
            <p>{t("Loading...")}</p>
          ) : (
            <div className="page-title-box">
              <Row className="align-items-center mb-3">
                <Col md={8}>
                  <h6 className="page-title">{t("Volunteers")}</h6>
                </Col>

                <Col md="4">
                  <div className="float-end d-none d-md-block">
                    <Link to="/volunteer/new" className="btn btn-primary">
                      {t("Create Volunteer")}
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <MDBDataTable
                      searchLabel={t("Search")}
                      infoLabel={[t("Showing"), t("to"), t("of"), t("entries")]}
                      noRecordsFoundLabel={t("No records found")}
                      paginationLabel={[t("Previous"), t("Next"), t("End")]}
                      noBottomColumns={true}
                      responsive
                      striped
                      bordered
                      data={data}
                      displayEntries={false}
                      pagination
                  />
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
