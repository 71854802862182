import React, { useEffect, useState } from "react";
import instance from "base_url";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact"; 

export default function SupportEvents() {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const isEmployee = localStorage.getItem("userType") === "employee";


  const getEvents = () => {
    setIsLoading(true);
    instance.get("/event/")
      .then((res) => {
        setEvents(res.data.results);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorOccurred(true);
      });
  };

  useEffect(() => {
    getEvents();
  }, []);

  //MDBDataTable
  const data = {
    columns: [
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Name")}
          </span>
        ),
        field: "name",
        sort: "asc",
        width: 200
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Support Type")}
          </span>
        ),
        field: "support_type",
        sort: "asc",
        width: 200
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Budget")}
          </span>
        ),
        field: "budget",
        sort: "asc",
        width: 100
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Number of Families")}
          </span>
        ),
        field: "number_of_families",
        sort: "asc",
        width: 150
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Event Days")}
          </span>
        ),
        field: "event_days",
        sort: "asc",
        width: 150
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Action")}
          </span>
        ),
        field: "action",
        sort: "disabled",
        width: 150
      }
    ],
    rows: events.map(event => ({
      name: event.name,
      support_type: event.support_type.name,
      budget: event.budget,
      number_of_families: event.number_of_families,
      event_days: event.event_days,
      action: (
        <>
          <Link to={`/event/${event.id}`} state={event} className="btn btn-sm btn-primary">
            {t('Show')}
          </Link>
          {isEmployee && (
          <Link style={{ marginLeft: "20px" }} to={`/event/${event.id}/edit`} state={event} className="btn btn-sm btn-primary">
            {t('Edit')}
          </Link>
          )}
        </>
      )
    }))
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {errorOccurred ? (
            <div>
              <p>{t("An Error Occurred!")}</p>
              <Button
                onClick={() => {
                  getEvents();
                }}
              >
                {t("Try again")}
              </Button>
            </div>
          ) : isLoading ? (
            <p>{t("Loading...")}</p>
          ) : (
            <div className="page-title-box">
              <Row className="align-items-center mb-3">
                <Col md={8}>
                  <h6 className="page-title">{t("Events")}</h6>
                </Col>

                {isEmployee && (
                <Col md="4">
                  <div className="float-end d-none d-md-block">
                    <Link to="/events/new" className="btn btn-primary">
                      {t("Create Support Event")}
                    </Link>
                  </div>
                </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <MDBDataTable
                     searchLabel={t("Search")}
                     infoLabel={[t("Showing"), t("to"), t("of"), t("entries")]}
                     noRecordsFoundLabel={t("No records found")}
                     paginationLabel={[t("Previous"), t("Next"), t("End")]}
                     noBottomColumns={true}
                     responsive
                     striped
                     bordered
                     data={data}
                     displayEntries={false}
                     pagination

                  />
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
