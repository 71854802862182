import instance from "base_url";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { MEDIA_URL } from "configs";
import { MDBDataTable } from 'mdbreact';


// Custom Accordion Toggle button
function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button
      type="button"
      className="btn btn-primary mb-1"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

export default function FamilyDetails() {
  const { id } = useParams();
  const [family, setFamily] = useState({});
  const [sending, setSending] = useState(false);
  const { t } = useTranslation();
  const visitId = new URLSearchParams(window.location.search).get("visit");

  const getFamily = async () => {
    try {
      const { data } = await instance.get(`/families/${id}/`);
      setFamily(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getFamily();
  }, [id]);

 // MDBDataTable
 const data = {
  columns: [
    {
      label: (
        <span>
          <i className="fas fa-sort"></i> {t("Name")}
        </span>
      ),
      field: "name",
      sort: "asc",
      width: 200
    },
    {
      label: (
        <span>
          <i className="fas fa-sort"></i> {t("Phone No.")}
        </span>
      ),
      field: "phone_no",
      sort: "asc",
      width: 200
    },
    {
      label: (
        <span>
          <i className="fas fa-sort"></i> {t("Gender")}
        </span>
      ),
      field: "gender",
      sort: "asc",
      width: 100
    },
    {
      label: (
        <span>
          <i className="fas fa-sort"></i> {t("Address")}
        </span>
      ),
      field: "address",
      sort: "asc",
      width: 200
    },
    {
      label: (
        <span>
          <i className="fas fa-sort"></i> {t("Status")}
        </span>
      ),
      field: "status",
      sort: "asc",
      width: 100
    },
    {
      label: (
        <span>
          <i className="fas fa-sort"></i> {t("Action")}
        </span>
      ),
      field: "action",
      sort: "disabled",
      width: 150
    }
  ],
  rows: family?.individuals?.map(item => ({
    name: item.name,
    phone_no: (
      <>
        {item.mobile_number} -{" "}
        <a href={`tel:${item.mobile_number}`}>{t("Call")}</a>
      </>
    ),
    gender: t(item.gender),
    address: (
      <>
        {t("Address")}{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps?q=${
            item.location.split(",")[0]
          },${item.location.split(",")[1]}`}
        >
          {t("see on map")}
        </a>
      </>
    ),
    status: (
      <span
        style={{
          color:
            item.status === 'alive'
              ? "green"
              : item.status === "dead"
              ? "red"
              : "black"
        }}
      >
        {t(item.status)}
      </span>
    ),
    action: (
      <Link
        to={`/individual/${item.id}?family=${family.id}${visitId ? `&visit=${visitId}` : ""}`}
        className={`btn btn-sm btn-${item.is_draft ? "warning" : "primary"}`}
      >
        {t("Details")} {item.is_draft ? `(${t("Draft")})` : ""}
      </Link>
    )
  })) || []
};

const sendTreeReport = async () => {
  setSending(true);
  const familyId = family.id;
  try {
    const response = await instance.get(`/families/${familyId}/send_genogram_report/`);
    setSending(false);
    if (response.status === 200) {
      alert("Report sent successfully");
    } else {
      alert("Failed to send report");
    }
  } catch (e) {
    setSending(false);
    console.error(e);
  }

}


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">{family.title}</h6>
              </Col>


              <Col md="4">
                <div className="d-flex justify-content-end align-items-center">
                 
                  {/* Add New Individual Button */}
                  <Link  
                    to={`/individual/new?family=${family.id}${visitId ? `&visit=${visitId}` : ""}`}
                    className="btn btn-primary mb-4">
                    {t("Add New Individual")}
                  </Link>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>

               {/*  family tree btn */}
            
             <Accordion defaultActiveKey="0" className="mb-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("Family Tree")}</Accordion.Header>
                <Accordion.Body>
                <button className="btn btn-danger mb-4" onClick={sendTreeReport}>{sending ? "sending ..." : t("Send Report to Email")}</button>
                <br />
                <img src={`${MEDIA_URL}genogram/${family.id}.png`} alt="Family Tree" />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

                {/* Family Details */}
                <MDBDataTable
                    searchLabel={t("Search")}
                    infoLabel={[t("Showing"), t("to"), t("of"), t("entries")]}
                    noRecordsFoundLabel={t("No records found")}
                    paginationLabel={[t("Previous"), t("Next"), t("End")]}
                    noBottomColumns={true}
                    responsive
                    striped
                    bordered
                    data={data}
                    displayEntries={false}
                    pagination
                />
              </Col>
            </Row>
        
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
