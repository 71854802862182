import React, { useEffect, useState } from "react";
import instance from "base_url";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { withTranslation } from "react-i18next"; 
import { MDBDataTable } from "mdbreact";

function Families({ t }) {
  const [families, setFamilies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);

  // Get Families
  const getFamilies = async () => {
    setIsLoading(true);
    try {
      const { data } = await instance.get("/families/");
      data.results.forEach(el => {
        const headIndividual = el.individuals.find(
          item => item.is_head_of_family
        );
        el.address = el.individuals.length ? el.individuals[0].address : null;
        el.head_name = headIndividual
          ? `${headIndividual.first_name} ${headIndividual.last_name}`
          : "-";
      });
      setFamilies(data.results);
      setIsLoading(false);
      setErrorOccurred(false);
    } catch (e) {
      setErrorOccurred(true);
      console.error("ERROR: ", e);
    }
  };

  useEffect(() => {
    getFamilies();
  }, []);

  // Prepare data for MDBDataTable
  const data = {
    columns: [
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Title")}
          </span>
        ),
        field: "title",
        sort: "asc",
        width: 150
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Head Name")}
          </span>
        ),
        field: "head_name",
        sort: "asc",
        width: 270
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Address")}
          </span>
        ),
        field: "address",
        sort: "asc",
        width: 200
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Action")}
          </span>
        ),
        field: "action",
        sort: "disabled",
        width: 100
      }
    ],
    rows: families.map(family => ({
      title: family.title,
      head_name: family.head_name,
      address: family.address,
      action: (
        <Link to={`/family/${family.id}`} state={family} className='btn btn-sm btn-primary'>
          {t("Details")}
        </Link>
      )
    }))
  };


  console.log("Data for MDBDataTable:", data);

  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {errorOccurred ? 
            <div>
              <p>{t("An Error Occurred!")}</p> 
              <Button onClick={() => { getFamilies(); }}>{t("Try again")}</Button> 
            </div>
            :
            isLoading ?
            <p>{t("Loading")}...</p>
            :
            <div className="page-title-box">
              <Row className="align-items-center mb-3">
                <Col md={8}>
                  <h6 className="page-title">{t("Families")}</h6> 
                </Col>

                <Col md="4">
                  <div className="float-end d-none d-md-block">
                    <Link to="/individual/new" className="btn btn-primary">
                      {t("Create Family")}
                    </Link> 
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <MDBDataTable
                    searchLabel={t("Search")}
                    infoLabel={[t("Showing"), t("to"), t("of"), t("entries")]}
                    noRecordsFoundLabel={t("No records found")}
                    paginationLabel={[t("Previous"), t("Next"), t("End")]}
                    noBottomColumns={true}
                    responsive
                    striped
                    bordered
                    data={data}
                    displayEntries={false}
                    pagination
                  />
                </Col>
              </Row>
            </div>
          }
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Families);
