import React, { useEffect, useState } from "react";
import instance from "base_url";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";  // استيراد MDBDataTable

export default function SupportCriteria() {
  const [supportCriterias, setSupportCriterias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const { t } = useTranslation();

  // جلب بيانات معايير الدعم
  const getSupportCriterias = async () => {
    setIsLoading(true);
    try {
      const { data } = await instance.get("/support-criteria/");
      setSupportCriterias(data.results);
      setIsLoading(false);
      setErrorOccurred(false);
    } catch (e) {
      setErrorOccurred(true);
      console.error("ERROR: ", e);
    }
  };

  useEffect(() => {
    getSupportCriterias();
  }, []);

  // MDBDataTable
  const data = {
    columns: [
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Title")}
          </span>
        ),
        field: "title",
        sort: "asc",
        width: 200
      },
      {
        label: (
          <span>
            <i className="fas fa-sort"></i> {t("Action")}
          </span>
        ),
        field: "action",
        sort: "disabled",
        width: 100
      }
    ],
    rows: supportCriterias.map(sc => ({
      title: sc.title,
      action: (
        <Link to={`/support-criteria/${sc.id}`} state={sc} className="btn btn-sm btn-primary">
          {t("Details")}
        </Link>
      )
    }))
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {errorOccurred ? (
            <div>
              <p>{t("An Error Occurred!")}</p>
              <Button
                onClick={() => {
                  getSupportCriterias();
                }}
              >
                {t("Try again")}
              </Button>
            </div>
          ) : isLoading ? (
            <p>{t("Loading...")}</p>
          ) : (
            <div className="page-title-box">
              <Row className="align-items-center mb-3">
                <Col md={8}>
                  <h6 className="page-title">{t("Support Criterias")}</h6>
                </Col>

                <Col md="4">
                  <div className="float-end d-none d-md-block">
                    <Link to="/support-criteria/new" className="btn btn-primary">
                      {t("Create Support Criteria")}
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <MDBDataTable
                      searchLabel={t("Search")}
                      infoLabel={[t("Showing"), t("to"), t("of"), t("entries")]}
                      noRecordsFoundLabel={t("No records found")}
                      paginationLabel={[t("Previous"), t("Next"), t("End")]}
                      noBottomColumns={true}
                      responsive
                      striped
                      bordered
                      data={data}
                      displayEntries={false}
                      pagination
                  />
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
